import React, { useState, useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import Config from '@/config'
import { useMixpanelGeneralSuperPropertiesTracker } from '@/hooks/api/useMixpanel'

const mixpanelToken = Config.MIXPANEL_TOKEN

type MixpanelInitializerProps = {
  children: JSX.Element
}

export const MixpanelInitializer = ({ children }: MixpanelInitializerProps) => {
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  useEffect(() => {
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken)
      setIsInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isInitialized ? (
    <MixpanelGeneralEventsWrapper>{children}</MixpanelGeneralEventsWrapper>
  ) : (
    children
  )
}

export const MixpanelGeneralEventsWrapper = ({
  children,
}: MixpanelInitializerProps) => {
  useMixpanelGeneralSuperPropertiesTracker()
  return children
}
