import isEmpty from 'lodash/isEmpty'
import { GA_EVENT_NAME_TYPE } from '@/utils/constants/trackGA'
import { SupportedCountries } from '@/types/commons/constants'

export const trackGAEvent = (
  eventName: GA_EVENT_NAME_TYPE,
  variables: Record<string, string | object> = {}
) => {
  const gaEvents = {
    event: eventName,
    ...variables,
  }

  if (window['dataLayer'] && !isEmpty(gaEvents)) {
    window['dataLayer'].push(gaEvents)
  }
}

export const getGAEventActionStepForm = ({
  step,
  country,
}: {
  step: number
  country: SupportedCountries
}) => {
  switch (step) {
    case 1:
      return `1st_step`
    case 2:
      return `2nd_step_${country}`
    case 3:
      return `3rd_step_${country}`
    case 4:
      return `4th_step_${country}`
    case 5:
      return `5th_step_${country}`
    case 6:
      return `6th_step_${country}`
    default:
      return ''
  }
}
