import { SOCIAL_MEDIA_SITE } from './enums'

export const SOCIAL_MEDIA_PLATFORMS = {
  1: SOCIAL_MEDIA_SITE.LINE,
  2: SOCIAL_MEDIA_SITE.VIBER,
  3: SOCIAL_MEDIA_SITE.FACEBOOK,
  4: SOCIAL_MEDIA_SITE.TELEGRAM,
  5: SOCIAL_MEDIA_SITE.WHATSAPP,
  6: SOCIAL_MEDIA_SITE.ZALO,
  7: SOCIAL_MEDIA_SITE.WIDGET,
  8: SOCIAL_MEDIA_SITE.DASH,
}
