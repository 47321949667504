export const GeneralEvents = {
  VISITED_PAGE: {
    name: 'Visited Page',
    props: {
      PRODUCT: 'Product',
      PAGE_NAME: 'Page Name',
      USER_ID: 'User ID',
      SYSTEM_ID: 'System ID',
      SNS_SOURCE: 'Social Network Services Source',
      NOTIFICATION_SOURCE: 'Notification Source',
    },
  },
  VISITED_PAGE_C2C_V2: {
    name: 'Visited C2C V2 Page',
    props: {
      PRODUCT: 'Product', //C2C_V2 on init
      PAGE_NAME: 'Page Name', //url of route
      USER_ID: 'User ID', //tracking id on dropoff page
      SYSTEM_ID: 'System ID', //country on init
    },
  },
  VISITED_PAGE_BAD_ADDRESS: {
    name: 'Visited Bad Address Page',
    props: {
      PRODUCT: 'Product',
      PAGE_NAME: 'Page Name',
      TRACKING_ID: 'Tracking ID',
      SYSTEM_ID: 'System ID',
    },
  },
}

export const ErrorEvents = {
  ERROR_PAGE: {
    name: 'Viewed Error Page',
  },
  C2C_ERROR_PAGE: {
    name: 'Viewed Order Processing Error Page C2C',
    props: {
      C2C_PAYMENT_ID: 'C2C Payment ID',
    },
  },
  FAILED_VALIDATION: {
    name: 'Viewed Failed Data Validation Page',
    props: {
      FLOW: 'Failed Data Validation Flow',
    },
  },
  C2C_V2_QR_CODE_ERROR_PAGE: {
    name: 'Viewed Error Page C2C V2',
    props: {
      ERROR_STATUS: 'Error Status',
      HASHED_TID: 'Hashed Tid',
      UNHASHED_TID: 'Unhashed Tid',
    },
  },
}

export const ConversionEvents = {
  SELF_HELP: {
    name: 'Clicked Self-help Features',
    props: {
      OPTION: 'Option selected',
      TRACKING_ID: 'Tracking ID',
    },
  },
  SAFE_PLACE_RADIO: {
    name: 'Clicked Safe Place Radio Buttons',
    props: {
      OPTION: 'Option selected',
      OTHERS: 'Other location (please specify)',
    },
  },
  SAFE_PLACE_SUBMIT: {
    name: 'Clicked Safe Place Submit',
  },
  NEIGHBOUR_SUBMIT: {
    name: 'Clicked Leave With Neighbor Submit',
  },
  PHONE: {
    name: 'Clicked Call Prior to Delivery Confirm',
  },
  DOORBELL: {
    name: 'Clicked Ring Doorbell Confirm',
  },
  ADDRESS: {
    name: 'Clicked Change Address Submit',
  },
}

export const C2cConversionEvent = {
  COLLECTION_METHOD: {
    name: 'Clicked C2c Collection Method',
    props: {
      OPTION_SELECTED: 'C2C Option selected',
    },
  },
  COLLECTION_METHOD_NEXT: {
    name: 'Clicked C2c Collection Method Next',
  },
  MAKE_PAYMENT: {
    name: 'Clicked C2c Make Payment',
    props: {
      CREATION_SOURCE: 'C2C Creation Source',
    },
  },
  ERROR_PAYMENT: {
    name: 'Viewed C2c Payment Error Toast',
    props: {
      PAYMENT_ERROR_FLOW: 'C2C Payment Error Flow',
      CREATION_SOURCE: 'C2C Creation Source',
    },
  },
  TRY_PAYMENT_AGAIN: {
    name: 'Clicked C2c Try Again',
    props: {
      CREATION_SOURCE: 'C2C Creation Source',
    },
  },
  SUCCESSFUL_PAYMENT: {
    name: 'Viewed C2c Payment Successful Page',
    props: {
      PAYMENT_SUCCESS_FLOW: 'C2C Payment Success Flow',
      CREATION_SOURCE: 'C2C Creation Source',
    },
  },
  ANOTHER_ORDER: {
    name: 'Clicked C2c Create Another Order',
    props: {
      CREATION_SOURCE: 'C2C Creation Source',
    },
  },
}

export const C2cV2ConversionEvent = {
  MAKE_PAYMENT: {
    name: 'Clicked C2c V2 Make Payment',
  },
  PAY: {
    name: 'Clicked C2c V2 Submit To Pay',
  },
  ERROR_PAYMENT: {
    name: 'Viewed C2c V2 Payment Error Toast',
  },
  TRY_PAYMENT_AGAIN: {
    name: 'Clicked C2c V2 Try Again',
  },
  SUCCESSFUL_PAYMENT: {
    name: 'Viewed C2c V2 Payment Successful Page',
  },
  VIEW_TNC: {
    name: 'Clicked C2c V2 T&c',
  },
  ACCEPT_TNC: {
    name: 'Clicked C2c V2 T&c Accept',
  },
}

export const BAD_ADDRESS_EVENTS = {
  // View events
  VIEWED_ADD_LANDMARK_HOMEPAGE: {
    name: 'Viewed Add Landmark Homepage',
  },
  // Error events
  VIEWED_UNABLE_TO_REVISE_ADDRESS_PAGE: {
    name: 'Viewed Unable to Revise Address Page',
  },
  // Conversion events
  CLICKED_ADD_LANDMARK_SUBMIT_BUTTON: {
    name: 'Clicked Add Landmark Submit Button',
    props: {
      LANDMARK: 'Landmark',
    },
  },
  CLICKED_UPDATE_ADDRESS: {
    name: 'Clicked Address Is Incorrect Update Address Button',
    props: {
      STREET_NAME: 'Street Name',
      BUILDING_NO: 'Building No',
      LANDMARK: 'Landmark',
      RWRT: 'RW/RT',
      SUBDISTRICT: 'Subdistrict',
    },
  },
  VIEWED_ADDRESS_IS_INCORRECT_PAGE: {
    name: 'Viewed Address Is Incorrect Page',
  },
  VIEWED_ADD_LANDMARK_SUCCESSFUL_CHANGE_PAGE: {
    name: 'Viewed Add Landmark Successful Change Page',
  },
  VIEWED_SUCCESSFUL_ADDRESS_CHANGE_PAGE: {
    name: 'Viewed Successful Address Change Page',
  },
  VIEWED_FAILED_ADD_LANDMARK_CHANGE_PAGE: {
    name: 'Viewed Failed Add Landmark Change Page',
  },
  VIEWED_FAILED_ADDRESS_CHANGE_PAGE: {
    name: 'Viewed Failed Address Change Page',
  },
}

export const PRODUCT_NAME = {
  CWP: 'Consignee Web Portal',
  C2C: 'C2C',
  C2C_V2: 'C2C_V2',
  BAD_ADDRESS: 'ID Robochat Bad Address',
}

export const SYSTEM_ID = {
  SG: 'SG',
  ID: 'ID',
}
