export const GA_EVENT_NAME = {
  FORM_SUBMIT_ENHANCE: 'form_submit_enhance',
  INTERACTIONS: 'interactions',
  PAGE_VIEW: 'page_view',
} as const

export type GA_EVENT_NAME_TYPE =
  (typeof GA_EVENT_NAME)[keyof typeof GA_EVENT_NAME]

export const GA_EVENT_CATEGORY = {
  DELIVERY_C2C_SG: 'delivery_c2c_sg',
} as const

export type GA_EVENT_CATEGORY_TYPE =
  (typeof GA_EVENT_CATEGORY)[keyof typeof GA_EVENT_CATEGORY]

export const GA_EVENT_LABEL = {
  PICKUP: 'pickup',
  DROPOFF: 'dropoff',
  PICKUP_PAYMENT: 'pickup_payment',
  DROPOFF_PAYMENT: 'dropoff_payment',
  PICKUP_PAID: 'pickup_paid',
  DROPOFF_PAID: 'dropoff_paid',
  DOMESTIC_SG: 'domestic_sg',
  INTERNATIONAL_SG: 'international_sg',
} as const
