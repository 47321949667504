import Config from '@/config'
import {
  FetchTransport,
  initializeFaro,
  ErrorsInstrumentation,
  WebVitalsInstrumentation,
  ConsoleInstrumentation,
  SessionInstrumentation,
} from '@grafana/faro-web-sdk'

const collectorUrl = `https://nv-${Config.ENVIRONMENT}-grafana-agent.ninjavan.co/collect`
const apiKey =
  Config.ENVIRONMENT === 'prod' ? 'vl4qLWk4XgAk7YK9' : 'danial_is_cool'

export function initialiseFaro() {
  const faro = initializeFaro({
    instrumentations: [
      new ErrorsInstrumentation(),
      new WebVitalsInstrumentation(),
      new ConsoleInstrumentation({
        disabledLevels: [],
      }),
      new SessionInstrumentation(),
    ],
    sessionTracking: {
      // default is false
      enabled: true,
      persistent: true,
      session: (window as any).__PRELOADED_STATE__?.faro?.session,
    },
    metas: [],
    transports: [
      new FetchTransport({
        url: collectorUrl,
        apiKey: apiKey,
      }),
    ],
    app: {
      name: 'consignee-web-portal',
      version: '1.0.0',
      environment: Config.ENVIRONMENT,
    },
  })

  return faro
}
