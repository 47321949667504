export default {
  TEXT: {
    GREY: '#8c8c8c',
    PAGE_DEFAULT: '#2e2e2e',
    RED: '#fa002c',
    LIGHT_GREY: '#e5e5e5',
    DARK_GREY: '#4c4c4c',
    DARK_RED_SELECTED: '#c31424',
    DARK_RED_UNSELECTED: '#9a191f',
    WHITE: '#fff',
    RED2: '#C2002F',
    GREY2: '#BFBFBF',
  },
  BACKGROUND: {
    WHITE: '#fff',
    BLACK: '#000',
    LIGHT_GREY: '#fcfcfc',
    GREY_BG: '#f8f8f8',
    NINJA_RED: '#c70023',
    LIKE_GREEN: '#5bc787',
    DISLIKE_RED: '#eb445a',
    BUTTON_DARK_GREY: '#2e2e2e',
    BUTTON_LIGHT_GREY: '#e5e5e5',
    BORDER_GREY: '#b3b3b3',
    BORDER_LIGHT_GREY: '#d9d9d9',
    RED: '#C2002F',
    LIGHT_GREY2: '#fafafa',
  },
}
