import { useEffect } from 'react'
import { GeneralEvents, PRODUCT_NAME } from '@/utils/constants/mixpanel'
import { useRouter } from 'next/router'
import { mpActions } from '@/components/Mixpanel/utils'
import { SOCIAL_MEDIA_PLATFORMS } from '@/utils/constants/platforms'

export const useMixpanelGeneralSuperPropertiesTracker = () => {
  const router = useRouter()

  useEffect(() => {
    const { platform } = router.query
    const hasTidParam =
      router.pathname === '/c2c/dropoff' && 'tid' in (router?.query || {})

    if (router.isReady && platform) {
      mpActions.register({
        [GeneralEvents.VISITED_PAGE.props.PRODUCT]:
          router.pathname === '/c2c' ? PRODUCT_NAME.C2C : PRODUCT_NAME.CWP,
        [GeneralEvents.VISITED_PAGE.props.SYSTEM_ID]:
          router.asPath.split('#')[1],
        [GeneralEvents.VISITED_PAGE.props.SNS_SOURCE]:
          SOCIAL_MEDIA_PLATFORMS[platform as string],
      })
    }
    // C2C_V2
    if (router.isReady && hasTidParam) {
      mpActions.register({
        [GeneralEvents.VISITED_PAGE_C2C_V2.props.PRODUCT]: PRODUCT_NAME.C2C_V2,
        [GeneralEvents.VISITED_PAGE_C2C_V2.props.SYSTEM_ID]:
          router.asPath.split('#')[1],
        [GeneralEvents.VISITED_PAGE_C2C_V2.props.PAGE_NAME]: router.pathname,
      })
    }
    // BAD_ADDRESS
    if (router.isReady && router.pathname === '/addressupdate') {
      mpActions.register({
        [GeneralEvents.VISITED_PAGE_BAD_ADDRESS.props.PRODUCT]:
          PRODUCT_NAME.BAD_ADDRESS,
        [GeneralEvents.VISITED_PAGE_BAD_ADDRESS.props.PAGE_NAME]:
          router.pathname,
        [GeneralEvents.VISITED_PAGE_BAD_ADDRESS.props.SYSTEM_ID]:
          router.asPath.split('#')[1],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.isReady])
}
