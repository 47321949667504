import mixpanel, { Dict } from 'mixpanel-browser'
import Config from '@/config'

const mpToken = Config.MIXPANEL_TOKEN

const wrapMp = (fn: () => void) => {
  try {
    if (mpToken) {
      fn()
    }
  } catch (e) {
    console.error(e)
  }
}

export const mpActions = {
  identify: (id: string) => {
    wrapMp(() => mixpanel.identify(id))
  },
  track: (name: string, props?: Dict) => {
    wrapMp(() => mixpanel.track(name, props))
  },
  register: (props: Dict) => {
    wrapMp(() => mixpanel.register(props))
  },
  people: {
    set: (props: Dict) => {
      wrapMp(() => mixpanel.people.set(props))
    },
  },
}
